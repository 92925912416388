import { useEffect } from 'react';

import HocSignUp from '@heureca/signUp/components/HocSignUp';
import hocUtils from '@heureca/shared/utils/hoc.utils';
import stepConstants from '@heureca/signUp/constants/step.constants';

const SignUpPage = () => {
  useEffect(() => {
    window.location.replace(process.env.GATSBY_FUNNEL_URL);
  }, []);

  // const params = new URLSearchParams(location.search);
  // const priceId = params.get('priceId');
  // const recurrenceId = params.get('recurrenceId');

  return null;

  // return (
  //   <SignUp priceId={priceId} recurrenceId={recurrenceId} />
  // );
};

export default hocUtils.compose(
  HocSignUp(stepConstants.SIGN_UP),
)(SignUpPage);
